import { API_URL } from "../api/constants";
import api from "../api/api";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export default {
    name: "general_mixins",
    data: () => ({
        API_URL: API_URL,
        api: api,
        collection_category: [
            {
                text: "Brands",
                align: "start",
                sortable: true,
                value: "brand",
            },
            { text: "Engine Capacity", value: "engine_capacity" },
            { text: "Fuel", value: "fuel_type" },
            { text: "Model", value: "model" },
            { text: "Technology", value: "engine_technology" },
            { text: "Years", value: "years" },
            { text: "Mark", value: "mark" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        order_headers: [
            { text: "Date/Time", value: "created_at" },
            { text: "Invoice Number #", value: "invoice_number" },
            { text: "Order Summary", value: "order_summary" },
            // { text: "Stage Status", value: "state" },
            { text: "Subtotal", value: "order_total_cost" },
            { text: "VAT", value: "order_tax" },
            { text: "Order Total (incl. Vat)", value: "order_total_withtax" },
            { text: "Order State", value: "workflow_stage" },
            //{ text: "Expected Delivery date", value: "delivery_date" },
            // { text: "Refund Issued", value: "refunded" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        intergroup_headers: [
            { text: "Date/Time", value: "created_at" },
            { text: "Delivered To", value: "transfer_to", divider: true },
            // { text: "To", value: "request_entity_for", divider:true},
            { text: "Transfer Number #", value: "invoice_number", divider: true },
            { text: "Order Summary", value: "order_summary", divider: true },
            // { text: "Stage Status", value: "state" },
            { text: "Order State", value: "workflow_stage", divider: true },
            //{ text: "Expected Delivery date", value: "delivery_date" },
            // { text: "Refund Issued", value: "refunded" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        order_shipping_headers: [
            { text: "#", value: "invoice_number" },
            { text: "Worflow Stage", value: "workflow_stage" },
            { text: "Stage Status", value: "state" },
            { text: "Cost", value: "order_total_cost" },
            { text: "Cost (inc. Vat)", value: "order_total_withtax" },
            //{ text: "Expected Delivery date", value: "delivery_date" },
            { text: "Expected", value: "delivery_date" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        locationHeaders: [
            { text: "Location", value: "floor_plan.location_name" },
            { text: "Aisle", value: "sector_aisle" },
            { text: "Bin", value: "rack_bin" },
            { text: "Shelf", value: "column_shelf" },
            { text: "Row", value: "row" },
            { text: "Logged", value: "created_at" },
        ],
        stockHeaders: [
            { text: "Available", value: "stock_available" },
            { text: "Balance", value: "stock_balance" },
            { text: "Primary Location", value: "primary_location" },
            { text: "Secondary Location", value: "secondary_location" },
            { text: "Logged", value: "created_at" },
        ],
    }),
    computed: {
        rt_workflow: {
            get() {
                return this.$store.getters["Backend/rt_workflow"];
            },
            set(val) {
                if (val) { this.$store.dispatch("Backend/fetch_workflow"); }
                this.$store.commit("Backend/SET_RT_WORKFLOW", val);
            },
        },
        rt_users: {
            get() {
                return this.$store.getters["Backend/rt_users"];
            },
            set(val) {
                if (val) { this.$store.dispatch("Backend/fetch_users"); }
                this.$store.commit("Backend/SET_RT_USERS", val);
            },
        },
        rt_organization: {
            get() {
                return this.$store.getters["Backend/rt_organization"];
            },
            set(val) {
                if (val) { this.$store.dispatch("Backend/fetch_organization"); }
                this.$store.commit("Backend/SET_RT_ORGANIZATION", val);
            },
        },
        rt_ui: {
            get() {
                return this.$store.getters["Backend/rt_ui"];
            },
            set(val) {
                if (val) { this.$store.dispatch("Backend/fetch_ui"); }
                this.$store.commit("Backend/SET_RT_UI", val);
            },
        },
        rt_catalogue: {
            get() {
                return this.$store.getters["Backend/rt_catalogue"];
            },
            set(val) {
                if (val) { this.$store.dispatch("Backend/fetch_catalogue"); }
                this.$store.commit("Backend/SET_RT_CATALOGUE", val);
            },
        },
        profile() {
            return this.$store.getters["Profile/getProfile"];
        },
        scan_mode_count: {
            get: function () {
                return this.$store.getters["Pos/scan_mode"];
            },
            set: function (newValue) {
                this.$store.dispatch("Pos/scan_mode", newValue);
            },
        },
        in_stock_only: {
            get: function () {
                return this.$store.getters["Pos/in_stock_only"];
            },
            set: function (newValue) {
                this.$store.dispatch("Pos/in_stock_only", newValue);
            },
        },
        supplierHeading() {
            return [
                { text: "Actions", value: "actions", sortable: false, divider: true },
                { text: "Name", value: "name", divider: true },
                { text: "Type", value: "type", divider: true },
                { text: "Address", value: "address", divider: true },
                { text: "Country", value: "country", divider: true },
                { text: "Phone Number", value: "phone_number", divider: true },
                { text: "Email", value: "email", divider: true },
                { text: "Comments", value: "comments", divider: true },
                { text: "Inventory Total", value: "inventory", divider: false },
            ]
        },
        Inventoryheaders() {
            let the_headers = [
                { text: "Actions", value: "actions", sortable: false, divider: true },
                { text: "Histories", value: "histories", sortable: false, divider: true },
                { text: "Uploads", value: "uploads", divider: true },
                // { text: "Old System Number", value: "old_system_number" }, //disabled as it is no longer necessary
            ];

            if (this.check_inventor_access_make) {
                the_headers.push({ text: "Make", value: "vehicle_make", divider: true });
            }

            if (this.check_inventor_access_system_number) {
                the_headers.push({ text: "Part #", value: "system_number" });
            }


            // if (this.check_inventory_access_oem_number) {
            //     the_headers.push({ text: "OEM Number", value: "oem_number" });
            // }

            if (this.check_inventory_access_oe_number) {
                the_headers.push({ text: "OE Number", value: "oe_number" });
            }

            if (this.check_inventory_access_alternative_number) {
                the_headers.push({ text: "Alterative number", value: "alter_numbers", divider: true });
            }

            the_headers.push({ text: "Full Description", value: "description", divider: true });

            if (this.check_inventory_access_condition) {
                the_headers.push({ text: "Condition", value: "condition", divider: true });
            }

            if (this.check_inventory_access_suppliers) {
                the_headers.push({ text: "Suppliers", value: "suppliers", divider: true });
            }


            // the_headers.push({ text: "FR/RR", value: "fr_rr" });
            // the_headers.push({ text: "LH/RH", value: "lh_rh" });

            if (this.check_inventory_access_selling_price) {
                the_headers.push({ text: "Selling Price", value: "selling_price" });
            }
            if (this.check_inventory_access_branch_price) {
                the_headers.push({ text: "Branch Price", value: "branch_price", divider: true });
            }
            if (this.check_inventory_access_cost_price) {
                the_headers.push({ text: "Cost Price", value: "cost_price" });
            }
            if (this.check_inventory_access_primary_location) {
                the_headers.push({ text: "Location 1", value: "primary_location" });
            }
            if (this.check_inventory_access_secondary_location) {
                the_headers.push({ text: "Location 2", value: "secondary_location", divider: true });
            }
            if (this.check_inventory_access_stock_available) {
                the_headers.push({ text: "QTY", value: "stock_available" });

            if(this.type != "low_stock"){
                if (this.check_inventory_access_stk) {
                    if (this.active_company != false && this.active_company.id != this.user.profile.branch_id) {
                        the_headers.push({ text: "STK", value: "get_branch_stock[0].stock_available" });
                    }
                }
            }
            }
            
            if (this.check_inventory_access_stock_available) {
                the_headers.push({ text: "MIN_QTY", value: "min_qty" });
            }
            

            // if (this.check_inventory_access_minstock) {
            //     the_headers.push({ text: "Min", value: "recommended_stock_min" , divider:true});
            // }
            if (this.check_inventory_access_shipped) {
                the_headers.push({ text: "Shipped HB 1", value: "backorder_qty", divider: true });
                the_headers.push({ text: "Shipped HB 2", value: "backorder_qty_2", divider: true });
                the_headers.push({ text: "Shipped HB 3", value: "backorder_qty_3", divider: true });
                the_headers.push({ text: "Shipped GT", value: "backorder_qty_4", divider: true });
                the_headers.push({ text: "Shipped HH", value: "backorder_qty_5", divider: true });
            }

            // the_headers.push(
            //     { text: "", value: "data-table-expand" },
            // );

            return the_headers;
        },
        PaymentInventoryheaders() {
            let the_headers = [
                { text: "Make", value: "vehicle_make", divider: true },
                { text: "Part #", value: "system_number", divider: true },
            ];
            the_headers.push({ text: "Part Name", value: "group_1", divider: true });
            the_headers.push({ text: "Full Description", value: "description" });

            // the_headers.push({ text: "FR/RR", value: "fr_rr" });
            // the_headers.push({ text: "LH/RH", value: "lh_rh" });
            the_headers.push({ text: "Packaging", value: "packaging", divider: true });

            if (this.check_inventory_access_selling_price) {
                the_headers.push({ text: "Unit Price", value: "selling_price" });
            }
            if (this.check_inventory_access_branch_price) {
                the_headers.push({ text: "Unit Price", value: "branch_price", divider: true });
            }
            if (this.check_inventory_access_stock_available) {
                the_headers.push({ text: "QTY", value: "stock" });
            }
            if (this.check_inventory_access_stock_available) {
                the_headers.push({ text: "In Stock", value: "stock_available" });
            }

            the_headers.push({ text: "Final Qty", value: "actions" });

            the_headers.push(
                { text: "", value: "data-table-expand" },
            );

            return the_headers;
        },
        ScannedOrderInventoryHeaders() {
            let the_headers = [
                {},
                { text: "Make", value: "vehicle_make", divider: true },
                // { text: "Old System Number", value: "old_system_number" }, //disabled as it is no longer necessary
                { text: "Part #", value: "system_number" },
            ];

            if (this.check_inventory_access_uploads) {
                the_headers.push({ text: "Uploads", value: "uploads", divider: true });
            }

            if (this.check_inventory_access_oe_number) {
                the_headers.push({ text: "OE Number", value: "oe_number" });
            }

            the_headers.push({ text: "Full Description", value: "description", divider: true });

            // the_headers.push({ text: "FR/RR", value: "fr_rr" });
            // the_headers.push({ text: "LH/RH", value: "lh_rh" });

            if (this.check_inventory_access_primary_location) {
                the_headers.push({ text: "Location 1", value: "primary_location" });
            }
            if (this.check_inventory_access_secondary_location) {
                the_headers.push({ text: "Location 2", value: "secondary_location", divider: true });
            }
            return the_headers;
        },
        check_inventory_access_cost_price() {
            let access = [
                "super admin",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_branch_price() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "dispatch",
                "branch admin",
                "branch special manager",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_selling_price() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                // "dispatch",
                // "branch inventory checker",
                // "warehouse inventory checker",
                // "branch stockworker",
                // "warehouse stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_primary_location() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                // "sales",
                "warehouse sales",
                "warehouse marketing manager",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_secondary_location() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                // "sales",
                "warehouse sales",
                "warehouse marketing manager",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_stock_available() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch manager",
                "branch special manager",
                // "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_stk() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "distributor",
                // "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_shipped() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_minstock() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                // "dispatch",
                // "branch inventory checker",
                // "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                // "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                // "warehouse sales",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_actions() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        validate_access_list(role) {
            if (role.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        // check_inventory_access_oem_number() {
        //     let access = [
        //         "super admin",
        //         "admin",
        //         "developer",
        //         "warehouse manager",
        //         "supplier manager",
        //         "dispatch",
        //         // "branch inventory checker",
        //         "warehouse inventory checker",
        //         // "warehouse stockworker",
        //         // "branch stockworker",
        //         // "branch admin",
        //         // "branch manager",
        //         // "branch cashier",
        //         // "sales",
        //         "warehouse sales",
        //         "distributor",
        //         //"customer",
        //     ];
        //     if (access.includes(this.user.profile.profile_type)) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
        check_inventor_access_make() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                // "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventor_access_system_number() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                "branch inventory checker",
                "warehouse inventory checker",
                "warehouse stockworker",
                "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                // "distributor_ltd",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_uploads() {
            let access = [
                "super admin",
                // "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                // "branch inventory checker",
                "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                // "branch cashier",
                "sales_cashier",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                // "distributor_ltd",
                //"customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_oe_number() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                // "branch inventory checker",
                "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                // "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
                //"customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_suppliers() {
            let access = [
                "super admin",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },

        check_inventory_access_alternative_number() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                "supplier manager",
                "dispatch",
                // "branch inventory checker",
                "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                // "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                "warehouse sales",
                // "distributor",
                //"customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_inventory_access_condition() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                // "dispatch",
                // "branch inventory checker",
                // "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                // "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                // "warehouse sales",
                // "distributor",
                //"customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_edit_access() {
            let access = [
                "super admin",
                "admin",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                // "dispatch",
                // "branch inventory checker",
                // "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                // "warehouse sales",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.profile.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        check_access() {
            let access = [
                "super admin",
                "admin",
                "developer",
                "warehouse manager",
                "warehouse submanager",
                // "supplier manager",
                // "dispatch",
                // "branch inventory checker",
                // "warehouse inventory checker",
                // "warehouse stockworker",
                // "branch stockworker",
                // "branch admin",
                // "branch manager",
                // "branch cashier",
                // "sales",
                // "warehouse sales",
                // "distributor",
                // "customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        user() {
            return this.$store.getters["Profile/getProfile"];
        },
        Organization() {
            return this.$store.getters["Organization/getOrganization"][0];
        },
        check_cart_access() {
            let access = [
                "super admin",
                "admin",
                "warehouse manager",
                "warehouse submanager",
                //"supplier manager",
                //"dispatch",
                //"branch inventory checker",
                //"warehouse inventory checker",
                //"warehouse stockworker",
                //"branch stockworker",
                "branch admin",
                "branch special manager",
                "branch manager",
                "branch cashier",
                "sales_cashier",
                "special sales",
                "sales",
                "warehouse sales",
                "warehouse marketing manager",
                "distributor",
                "distributor_ltd",
                //"customer",
            ];
            if (access.includes(this.user.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        active_company: {
            get: function () {
                return this.$store.getters["Pos/active_company"];
            },
            set: function (newValue) {
                this.$store.dispatch("Pos/clearCart");
                this.$store.dispatch("Pos/active_company", newValue);
            },
        },

        get_active_company: {
            get: function () {
                return this.$store.getters["Pos/active_company_low_stock"];
            },
            set: function (newValue) {
                this.$store.dispatch("Pos/clearCart");
                this.$store.dispatch("Pos/active_company_low_stock", newValue);
            },
        },
    },
    methods: {
        check_permissions(access) {
            if (access.includes(this.profile.profile.profile_type)) {
                return true;
            } else {
                return false;
            }
        },
        async warmCache() {
            await Promise.all([
                this.$store.dispatch("HeadlesCms/fetch_menu"),
                this.$store.dispatch("Catalogue/fetch_Catalogue"),
                this.$store.dispatch("Catalogue/fetchCatalogueTypes"),
                this.$store.dispatch("Catalogue/fetch_Inventory"),
                this.$store.dispatch("Organization/fetch_Organization"),
                this.$store.dispatch("Organization/fetch_Branch"),
                this.$store.dispatch("Organization/fetchBranchByType", "warehouse"),
                this.$store.dispatch("Organization/fetchBranchByType", "supplier"),
                this.$store.dispatch("Organization/fetchBranchByType", "branch"),
                this.$store.dispatch("Backend/fetch_all", "branch"),
                this.$store.dispatch("Backend/fetch_all", "meta_key"),
                this.$store.dispatch("Organization/fetchStaff"),
                this.$store.dispatch("Supplier/fetchSuppliers"),
                this.$store.dispatch("Reports/reporting_branches"),
                this.$store.dispatch("Reports/reporting_customers"),
                this.$store.dispatch("Pos/fetch_user_branch_stock_history"),
                // await this.active_company = this.profile.profile.get_branch[0].id,
            ])
        },
        vatCalc(cost) {
            //set default vat value;
            let vat = this.profile.profile.get_branch[0].sales_vat;

            // //if entity is not the active company, set vat to the entity's vat
            // if((this.active_company.id !== this.profile.profile.get_branch[0].id)){
            //     vat = this.profile.profile.get_branch[0].order_vat
            // }

            if (this.active_company.id !== this.profile.profile.get_branch[0].id && this.active_company.entity_type == "warehouse") { //PLACING ORDER TO WAREHOUSE
                vat = this.profile.profile.get_branch[0].order_vat;
            } else { //POS SALE
                vat = this.profile.profile.get_branch[0].sales_vat;

            }
            return (cost / 100) * (vat + 100);
        },
        recall_orders(period) {
            var notyf = new Notyf();
            notyf.success({
                message: "Processing Update Started!",
                background: "orange",
            });
            this.$store.dispatch("Warehouse/fetchOrdersByState", period).then(() => {
                notyf.success("Update Complete!");
            });
        },
        // Recalling of orders (refresh button) on workflows
        recall_workflow_orders(period) {

            this.disabled = true; // Setting disabled state to true

            var notyf = new Notyf();
            notyf.success({
                message: "Processing Update Started!",
                background: "orange",
            });

            this.$store.dispatch("Warehouse/fetchOrdersGroupToGroupPeriod", period)
                .then(() => {
                    notyf.success("Update Complete!");
                    setTimeout(() => {
                        this.disabled = false; // Setting this.disabled to false after 2 seconds delay
                    }, 2000); // Delay of 2000 milliseconds (2 seconds)
                })
                .catch(error => {
                    // Handle dispatch error if needed
                    console.error("Error:", error);
                    notyf.error("Error processing update");

                    this.disabled = false; // Resetting disabled state in case of error
                });
        },
        recall_workflow_receiving(period) {

            this.disabled = true; // Setting disabled state to true


            var notyf = new Notyf();
            notyf.success({
                message: "Processing Update Started!",
                background: "orange",
            });
            this.$store.dispatch("Warehouse/fetchOrdersReceivingPeriod", period)
                .then(() => {
                    notyf.success("Update Complete!");
                    setTimeout(() => {
                        this.disabled = false; // Setting this.disabled to false after 2 seconds delay
                    }, 2000); // Delay of 2000 milliseconds (2 seconds)
                })
                .catch(error => {
                    // Handle dispatch error if needed
                    console.error("Error:", error);
                    notyf.error("Error processing update");

                    this.disabled = false; // Resetting disabled state in case of error
                });
        },
        recall_workflow_POS(period) {

            this.disabled = true; // Setting disabled state to true

            var notyf = new Notyf();
            notyf.success({
                message: "Processing Update Started!",
                background: "orange",
            });
            this.$store.dispatch("Warehouse/fetchOrdersPOSPeriod", period)
                .then(() => {
                    notyf.success("Update Complete!");
                    setTimeout(() => {
                        this.disabled = false; // Setting this.disabled to false after 2 seconds delay
                    }, 2000); // Delay of 2000 milliseconds (2 seconds)
                })
                .catch(error => {
                    // Handle dispatch error if needed
                    console.error("Error:", error);
                    notyf.error("Error processing update");

                    this.disabled = false; // Resetting disabled state in case of error
                });
        },
        //used to update the orders id, this will update the orders list inside of workflow
        fetchOrderDetailsById(orderID) {
            this.$store.dispatch("Workflow/fetch_orders_by_id", orderID);
        },
    },

};
